const btn = document.querySelector(".header__btn-menu");
const nav = document.querySelector(".header__nav");
const links = document.querySelectorAll(".js-link");
const items = document.querySelectorAll(".help__item");

function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

btn.addEventListener("click", () => {
    btn.classList.toggle("header__btn-menu--close");
    nav.classList.toggle("header__nav--open");
    document.body.classList.toggle("overflow-hidden");
});

links.forEach(link => {
    link.addEventListener("click", () => {
        btn.classList.remove("header__btn-menu--close");
        nav.classList.remove("header__nav--open");
        document.body.classList.remove("overflow-hidden");
    });
})

items.forEach(item => {
    const btn = item.querySelector(".js-copy");

    btn?.addEventListener("click", () => {
        const info = item.querySelector(".help__info");
        copyToClipboard(info.textContent.trim())
            .then(() => console.log('text copied !'))
            .catch(() => console.log('error'));
        btn.style.backgroundColor = "#88cc00";

        const timer = setInterval(() => {
            btn.style.backgroundColor = "";
            clearInterval(timer);
        }, 1000)
    });
});